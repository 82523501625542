import React, { useRef, useState } from "react";
import Layout from "components/layout";
import { useForm } from "react-hook-form";
import { useAddConference } from "hooks/Confrence/useAddConference";
import Loader from "components/loader";
import Modal from "components/popup";
import { Editor } from "@tinymce/tinymce-react";
import { CountryArray } from "utils/constants";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import Seo from "components/seo";

export default function PostConf() {
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(false);
  const [descriptionVal, setDescription] = useState(false);
  const captchaRef = useRef(null);
  const { status, mutateAsync } = useAddConference();
  const [imageupload, setImageUpload] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleEditorChange = (e) => {
    setDescription(e.target.getContent());
  };

  const handleUploadImage = (e) => {
    const files = e.target.files[0];
    setImageUpload(files);
  };

  const onSubmit = (data) => {
    if (token) {
      const formData = new FormData();
      // formData.append("document", files[0]);
      formData.append("start_date", data.start_date);
      formData.append("end_date", data.end_date);
      formData.append("title", data.title);
      formData.append("meta_description", data.meta_description);
      formData.append("slug", data.slug);
      formData.append("place", data.place);
      formData.append("country", data.country);
      formData.append("description", descriptionVal);
      formData.append("email", data.email);
      formData.append("url_link", data.url_link);
      formData.append("conf_image", imageupload);
      formData.append("language", "English");

      // const postData = {
      //   start_date: data.start_date,
      //   end_date: data.end_date,
      //   title: data.title,
      //   meta_description: data.meta_description,
      //   slug: data.slug,
      //   place: data.place,
      //   country: data.country,
      //   description: descriptionVal,
      //   email: data.email,
      //   url_link: data.url_link,
      //   conf_image: data.conf_image,
      //   language: "English",
      // };
      mutateAsync(formData).then((_) => {
        setOpen(true);
        reset();
      });
    } else {
      alert("Please verify captcha");
    }
  };

  const handleVerify = () => {
    setToken(true);
  };

  return (
    <Layout>
      <Seo
        title="Add New Translation Conference"
        slug="/translation-conference/add"
      />
      <Modal open={open} setOpen={setOpen} />
      <div className="mainContainer font-secondary ">
        <div className="banner w-full bg-translation-confrence bg-no-repeat bg-cover bg-center h-[300px]">
          {" "}
        </div>
        <div className="bottomBackground w-full bg-darkestgray text-white ">
          <div className="bottomContainer lg:w-9/12 xl:w-4/12 w-full mx-auto py-10 lg:px-0 px-4 flex-shrink-0">
            <div className="headImage flex ">
              <img
                src="https://tomedes.gumlet.io/frontend/images/new-conf-user.png"
                alt=""
              />
              <h1 className="text-2xl">Add New Conference</h1>
            </div>
            <h5 className="font-semibold border-b border-inputBorder pt-4 pb-2 text-sm">
              Fill Out Conference Details
            </h5>
            <form
              className="formBox flex-col"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="firstRow flex flex-col md:flex-row md:justify-between mx-auto pt-4 w-full  ">
                <div className="heading md:w-3/12 w-full">
                  <h5 className="font-light whitespace-nowrap lg:pb-0 pb-4 text-sm">
                    Conference Date
                  </h5>
                </div>
                <div className="flex flex-col md:flex-row gap-x-4 md:justify-between md:w-9/12 w-full">
                  <div className=" flex flex-col md:w-1/2 w-full">
                    <input
                      type="date"
                      name="start_date"
                      className=" py-2 px-2 text-blackGray font-light w-full"
                      {...register("start_date", { required: true })}
                    />
                    {errors.start_date && (
                      <span className="text-red text-xs pt-2">
                        {errorMessage}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col md:w-1/2 w-full mt-4 md:mt-0">
                    <input
                      type="date"
                      name="end_date"
                      className=" py-2 px-2 text-blackGray font-light w-full"
                      {...register("end_date", { required: true })}
                    />
                    {errors.end_date && (
                      <span className="text-red text-xs pt-2">
                        {errorMessage}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="secondRow  lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-full  ">
                <h5 className="md:w-3/12 w-full text-sm font-light">Title</h5>
                <div className="md:flex-col flex-row md:w-9/12 mt-4 md:mt-0">
                  <input
                    name="title"
                    type="text"
                    className="py-2  w-full px-2 text-blackGray font-light"
                    placeholder="Add conference title"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <span className="text-red text-xs pt-2">
                      {errorMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="secondRow lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-full  ">
                <h5 className="md:w-3/12 w-full text-sm font-light">
                  Meta Description
                </h5>
                <div className="flex-col md:w-9/12 mt-4 md:mt-0">
                  <input
                    type="text"
                    name="meta_description"
                    className="py-2 px-2  w-full text-blackGray font-light"
                    placeholder="Enter conference meta description"
                    {...register("meta_description", { required: true })}
                  />
                  {errors.meta_description && (
                    <span className="text-red text-xs pt-2">
                      {errorMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="secondRow lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-full  ">
                <h5 className="md:w-3/12 w-full text-sm font-light ">Url</h5>
                <div className="flex-col md:w-9/12 mt-4 md:mt-0">
                  <input
                    type="text"
                    name="slug"
                    className="py-2  px-2 w-full text-blackGray font-light"
                    placeholder="Enter conference url"
                    {...register("slug", { required: true })}
                  />
                  {errors.slug && (
                    <span className="text-red text-xs pt-2">
                      {errorMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="secondRow lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-full items-center">
                <h5 className="md:w-3/12 w-full text-sm font-light ">
                  Location
                </h5>
                <div className="flex-col md:w-9/12 mt-4 md:mt-0">
                  <input
                    type="text"
                    name="place"
                    className="py-2  px-2 w-full text-blackGray font-light"
                    placeholder="Add location name"
                    {...register("place", { required: true })}
                  />
                  {errors.place && (
                    <span className="text-red text-xs pt-2">
                      {errorMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="secondRow lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-full  ">
                <h5 className="md:w-3/12 w-full text-sm font-light ">
                  Country
                </h5>
                <div className="flex-col md:w-9/12 mt-4 md:mt-0">
                  <select
                    id="countries"
                    name="country"
                    className="appearance-none block w-full  bg-white text-gray-700 text-blackGray font-light border border-grayInput  py-1 px-2 focus:outline-none focus:bg-white focus:border-gray-500"
                    {...register("country", { required: true })}
                  >
                    <option disabled selected>
                      Select Country
                    </option>
                    {CountryArray
                      ? CountryArray.map(({ name }, index) => (
                          <option
                            key={index}
                            value={name}
                            className="text-blackGray text-2xl font-light"
                          >
                            {name}
                          </option>
                        ))
                      : null}
                    {errors.country && (
                      <span className="text-red text-xs pt-2">
                        {errorMessage}
                      </span>
                    )}
                  </select>
                </div>
              </div>
              <div className="secondRow lg:pb-0 pb-4 pt-8 flex flex-col md:flex-row md:justify-between mx-auto w-full  ">
                <h5 className="md:w-3/12 w-full text-sm font-light ">
                  Description
                </h5>
                <div className="py-2 md:w-9/12 w-full text-black font-light mt-4 md:mt-0">
                  <Editor
                    initialValue="Your text"
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image",
                        "charmap print preview anchor help",
                        "searchreplace visualblocks code",
                        "insertdatetime media table paste wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic | \
                         alignleft aligncenter alignright | \
                         bullist numlist outdent indent | help",
                    }}
                    onChange={handleEditorChange}
                  />
                </div>
              </div>{" "}
              <div className="secondRow lg:pb-0 pb-4 pt-8  flex flex-col md:flex-row md:justify-between mx-auto w-full items-center">
                <h5 className="md:w-3/12 w-full text-sm font-light ">Email</h5>
                <div className="flex-col md:w-9/12 mt-4 md:mt-0">
                  <input
                    type="email"
                    name="email"
                    className="py-2  px-2 w-full text-black font-light"
                    placeholder="abc@gmail.com "
                    {...register("email", {
                      required: true,
                      pattern: patternEmail,
                    })}
                  />
                  {errors.email && (
                    <span className="text-red text-xs pt-2">
                      {errorMessageEmail}
                    </span>
                  )}
                </div>
              </div>
              <div className="secondRow lg:pb-0 pb-4 pt-8 flex flex-col md:flex-row md:justify-between mx-auto w-full  ">
                <h5 className="md:w-3/12 w-full text-sm font-light ">
                  Web Addess URL
                </h5>
                <div className="flex-col md:w-9/12 mt-4 md:mt-0 ">
                  <input
                    type="text"
                    name="url_link"
                    className="py-2 px-2 w-full text-black font-light"
                    placeholder="http://example.com"
                    {...register("url_link", { required: true })}
                  />
                  {errors.email && (
                    <span className="text-red text-xs pt-2">
                      {errorMessage}
                    </span>
                  )}
                </div>
              </div>
              <div className="firstRow flex flex-col md:flex-row md:justify-between mx-auto items-center pt-4 w-full relative ">
                <div className="heading md:w-3/12 w-full">
                  <h5 className="font-light whitespace-nowrap lg:pb-0 pb-4 text-sm">
                    Upload Image
                  </h5>
                </div>
                <div className="inputs flex justify-start gap-x-4 w-full md:w-9/12 mt-4 md:mt-0 relative">
                  <input
                    type=""
                    className="w-full py-2 px-2 text-blackGray bg-blackGray font-light"
                    placeholder="Note: Image size should be greater than 240x175"
                    disabled
                  />
                  {errors.conf_image && (
                    <span className="text-red text-xs pt-2 absolute bottom-[-27px]">
                      {errorMessage}
                    </span>
                  )}

                  <label className="w-28 flex flex-col items-center bg-lightBlue rounded text-white py-1 shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-hoverBtn hover:text-white">
                    <span className="mt-2 text-base leading-normal">
                      Select
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      name="conf_image"
                      {...register("conf_image")}
                      onChange={handleUploadImage}
                    />
                  </label>
                </div>
              </div>
              <div className="secondRow lg:pb-0 pb-4 pt-8 items-center flex flex-col md:flex-row md:justify-between mx-auto w-full  ">
                <h5 className="md:w-3/12 w-full text-sm font-light ">
                  Captcha
                </h5>
                <div className="py-2 md:w-9/12 px-2 text-black font-light">
                  <HCaptcha
                    sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
                    onVerify={handleVerify}
                    ref={captchaRef}
                  />
                </div>
              </div>
              <button className="bg-lightBlue px-16 py-3 mt-4" type="submit">
                {status === "loading" ? <Loader /> : "Add more"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
